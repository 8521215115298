import React, { FC, useState } from 'react';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { DataLiquidity } from '../../../../../redux/reducers/walletBalance/types';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: DataLiquidity;
	name: string;
}

const Item: FC<Props> = ({ item, name }) => {
	const { code, valueCrypto, platformBalanceValue, pendingFeeValue, B2C2BalanceValue } = item;
	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<div className="td__wrap">
						<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						<p className="td-name">{code.toUpperCase()}</p>
						<span className="td-more-info">{name}</span>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Total Liquidity</p>
					<div className="td__wrap">
						<p>{toFixedNumber(valueCrypto, code.toUpperCase(), true)}</p>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Platform Balances</p>
					<div className="td__wrap">
						<p>{toFixedNumber(platformBalanceValue, code.toUpperCase(), true)}</p>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Pending Fees</p>
					<div className="td__wrap">
						<p>{toFixedNumber(pendingFeeValue, code.toUpperCase(), true)}</p>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Provider Balances</p>
					<div className="td__wrap">
						<p>{toFixedNumber(B2C2BalanceValue, code.toUpperCase(), true)}</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default Item;
