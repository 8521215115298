import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

import Header from 'layouts-elements/Header/Header/Header';
import Footer from 'layouts-elements/Footer';
import { useHistory } from 'react-router';
import { IDashboard } from './types';
import { ROUTES } from '../../routes/constants';

// ==========================================:
const Dashboard: FC<IDashboard> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';
	const history = useHistory();

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>

			{/* <Header /> */}
			<main className="main">{children}</main>
			{history.location.pathname !== ROUTES.MAINTENANCE && <Footer />}
		</>
	);
};

export default Dashboard;
