import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { handleFeeFixedChange } from 'services/utils/decimalLimits';
import { numberInputFix } from '../../../../services/utils/numberInputFix';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';
/* eslint-disable @typescript-eslint/no-explicit-any */

const SpreadManagementItem: FC<any> = ({ data, updateHandler, permission }) => {
	const id = data?.id;
	const code = data?.code;
	const spread = data?.spread;
	const [changeableMode, setChangeableMode] = useState<boolean>(false);
	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		spread: spread || '',
	};

	const splitText = (text: string) => {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	};

	const [oneText, twoText] = splitText(code.toUpperCase());
	console.log(data);

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							asset_pair_id: id,
							spread: Number(values.spread),
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr is-edit" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Pair</p>
								<div className="td__coin-pair">
									<div className="td__coin-pair-item">
										{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
										<img src={`/img/currencies/${oneText.toLowerCase()}.svg`} alt={oneText} />
										<p className="td-name">{oneText}</p>
										<span className="td-more-info">{data?.base_asset?.name}</span>
									</div>
									<svg
										width="16"
										height="16"
										viewBox="0 0 16 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M12.1482 4.60577C12.3565 4.39749 12.3565 4.0598 12.1482 3.85152C11.94 3.64324 11.6023 3.64324 11.394 3.85152L7.99996 7.24556L4.60594 3.85154C4.39766 3.64326 4.05997 3.64326 3.85169 3.85154C3.64341 4.05982 3.64341 4.39751 3.85169 4.60578L7.24571 7.9998L3.85152 11.394C3.64324 11.6023 3.64324 11.94 3.85152 12.1482C4.0598 12.3565 4.39749 12.3565 4.60577 12.1482L7.99996 8.75405L11.3942 12.1483C11.6024 12.3565 11.9401 12.3565 12.1484 12.1483C12.3567 11.94 12.3567 11.6023 12.1484 11.394L8.75421 7.9998L12.1482 4.60577Z"
											fill="#5C6277"
										/>
									</svg>
									<div className="td__coin-pair-item">
										{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
										<img src={`/img/currencies/${twoText.toLowerCase()}.svg`} alt={twoText} />
										<p className="td-name">{twoText}</p>
										<span className="td-more-info">{data?.quote_asset?.name}</span>
									</div>
								</div>
							</div>

							<div className="td">
								<p className="td-hidden-name">Spread %</p>
								<input
									className="table__input"
									type="number"
									onKeyDown={(event) => numberInputFix(event)}
									name="spread"
									onChange={(event) =>
										handleFeeFixedChange(event, props.setFieldValue, 'spread', 'percent')
									}
									onWheel={(event) => event.currentTarget.blur()}
									onBlur={props.handleBlur}
									value={props.values.spread}
									placeholder="0"
									max={20}
								/>
							</div>
							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Pair</p>
						<div className="td__coin-pair">
							<div className="td__coin-pair-item">
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<img src={`/img/currencies/${oneText.toLowerCase()}.svg`} alt={oneText} />
								<p className="td-name">{oneText}</p>
								<span className="td-more-info">{data?.base_asset?.name}</span>
							</div>
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M12.1482 4.60577C12.3565 4.39749 12.3565 4.0598 12.1482 3.85152C11.94 3.64324 11.6023 3.64324 11.394 3.85152L7.99996 7.24556L4.60594 3.85154C4.39766 3.64326 4.05997 3.64326 3.85169 3.85154C3.64341 4.05982 3.64341 4.39751 3.85169 4.60578L7.24571 7.9998L3.85152 11.394C3.64324 11.6023 3.64324 11.94 3.85152 12.1482C4.0598 12.3565 4.39749 12.3565 4.60577 12.1482L7.99996 8.75405L11.3942 12.1483C11.6024 12.3565 11.9401 12.3565 12.1484 12.1483C12.3567 11.94 12.3567 11.6023 12.1484 11.394L8.75421 7.9998L12.1482 4.60577Z"
									fill="#5C6277"
								/>
							</svg>

							<div className="td__coin-pair-item">
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<img src={`/img/currencies/${twoText.toLowerCase()}.svg`} alt={twoText} />
								<p className="td-name">{twoText}</p>
								<span className="td-more-info">{data?.quote_asset?.name}</span>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Spread %</p>
						<div className="td__wrap">
							<p>{toFixedNumber(spread, '')}</p>
						</div>
					</div>
					<div className="td td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button
									type="button"
									onClick={switchOnChangeableMode}
									className="btn--icon btn--edit"
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SpreadManagementItem;
