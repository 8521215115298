import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SortedButton, { TSortedDirection } from 'ui/SortedButton';
import { EPendingWithdrawalFields, EPendingWithdrawalType } from 'services/api/transactions/types';
import { addFilter } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { getCryptoCurrencyData, getFiatCurrencyData } from 'redux/reducers/currency/selectors';
import FilterDateSelect from 'ui/Formik/Select/FilterDateSelect';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import {
	INIT_FILTER_OPTIONS,
	STATUS_POOLED_DEPOSIT_FILTER_OPTIONS,
} from 'redux/reducers/transactionsFilters/constants';
import {
	THistoryFiltersOption,
	THistoryFiltersTypeOption,
} from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { ITransactionsTab } from '../../../types';

const PendingWithdrawalTableHeader: FC<
	ITransactionsTab & { assetType: EPendingWithdrawalType }
> = ({ assetType, setSorted }) => {
	const dispatch = useDispatch();
	const [sortedGross, setSortedGross] = useState<TSortedDirection>('');
	const [sortedFee, setSortedFee] = useState<TSortedDirection>('');
	const [sortedNet, setSortedNet] = useState<TSortedDirection>('');
	const [sortedType, setSortedType] = useState<TSortedDirection>('');

	const onSortedGross = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EPendingWithdrawalFields.GROSS, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedGross(direction);
		setSortedType('');
	};
	const onSortedFee = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EPendingWithdrawalFields.FEE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedFee(direction);
		setSortedNet('');
		setSortedGross('');
	};

	const onSortedNet = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EPendingWithdrawalFields.NET, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedNet(direction);
		setSortedFee('');
		setSortedGross('');
	};
	const onSortedType = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EPendingWithdrawalFields.TYPE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedType(direction);
		setSortedGross('');
	};

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.ASSET_ID,
				filterType:
					assetType === EPendingWithdrawalType.CRYPTO
						? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
						: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
			}),
		);
	};

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.STATUS,
				filterType:
					assetType === EPendingWithdrawalType.CRYPTO
						? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
						: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
			}),
		);
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType:
					assetType === EPendingWithdrawalType.CRYPTO
						? EFiltersType.TRANSACTIONS_CRYPTO_PENDING_WITHDRAWALS
						: EFiltersType.TRANSACTIONS_FIAT_PENDING_WITHDRAWALS,
			}),
		);
	};

	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const cryptoCurrencyFilterOptions = cryptoCurrencyData.map(({ id, code }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
	}));
	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const fiatCurrencyFilterOptions = fiatCurrencyData.map(({ id, code }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
	}));

	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					{/* <FilterDateSelect title="Timestamp" onChange={onChangeDate} /> */}
					<p>Timestamp</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Client Name</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Currency</p>
				</div>
			</div>
			<div className="td">
				<SortedButton direction={sortedGross} title="Gross" handleSort={onSortedGross} />
			</div>
			{/* {assetType === EPendingWithdrawalType.CRYPTO && (
				<div className="td">
					<SortedButton direction={sortedFee} title="Fee" handleSort={onSortedFee} />
				</div>
			)} */}
			<div className="td">
				<SortedButton direction={sortedFee} title="Fee" handleSort={onSortedFee} />
			</div>
			{/* {assetType === EPendingWithdrawalType.CRYPTO && (
				<div className="td">
					<SortedButton direction={sortedNet} title="Net" handleSort={onSortedNet} />
				</div>
			)} */}
			<div className="td">
				<SortedButton direction={sortedNet} title="Net" handleSort={onSortedNet} />
			</div>

			<div className="td">
				<div className="td-name">
					<p>Type</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Destination</p>
				</div>
			</div>
			{assetType === EPendingWithdrawalType.CRYPTO && (
				<div className="td">
					<div className="td-name">
						<p>Tx Hash</p>
					</div>
				</div>
			)}
			{/* {assetType === EPendingWithdrawalType.CRYPTO && (
				<div className="td">
					<div className="td-name">
						<p>Tx ID</p>
					</div>
				</div>
			)} */}
			<div className="td">
				<div className="td-name">
					<p>Tx ID</p>
				</div>
			</div>

			<div className="td">
				<div className="td-name">
					{/* <FilterSelect
						title="Status"
						options={
							[
								...INIT_FILTER_OPTIONS,
								...STATUS_POOLED_DEPOSIT_FILTER_OPTIONS,
							] as THistoryFiltersTypeOption[]
						}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleStatusChange}
					/> */}
					<p>Status</p>
				</div>
			</div>
			<div className="td td--center">
				<div className="td-name" />
			</div>
		</div>
	);
};

export default PendingWithdrawalTableHeader;
