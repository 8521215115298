import { IAsset } from '../currency/types';

export interface IPagination<T> {
	current_page: number;
	data: T[];
	per_page: number;
	total: number;
	last_page: number;
	from: number;
}

export interface ITradeLog {
	id: number;
	transaction_id: number;
	user_id: number;
	from_asset_id: number;
	to_asset_id: number;
	sell_quantity: number;
	buy_quantity: number;
	quantity: number;
	crypto_quantity: number;
	exchange_rate: number;
	exchange_rate_with_spread: number;
	last_input: string;
	request_order: string;
	response: string;
	created_at: string;
	updated_at: string;
	from_asset: IAsset;
	to_asset: IAsset;
	user_balance: number;
	trade_fee: number;
	spread_fee: number;
}

export type ITradeLogsWithPagination = IPagination<ITradeLog>;

export interface ILogsStore {
	tradeLogs: ITradeLogsWithPagination | null;
	loading: boolean;
}
export interface ILogsRequestPayload {
	per_page?: number;
	current_page?: number;
}

export enum ETradeLogsType {
	EXTERNAL = 'external',
	INTERNAL = 'internal',
}
