import {
	getExternalLogsReport,
	getExternalLogsRequest,
	getInternalLogsReport,
	getInternalLogsRequest,
} from 'redux/reducers/logs/reducer';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTradeLogs } from 'redux/reducers/logs/selectors';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import Loading from 'layouts-elements/Loading/Loading';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { ETradeLogsType } from 'redux/reducers/logs/types';
import ReactPaginate from 'react-paginate';
import TradeLogsTableHeader from './TradeLogsTableHeader';
import TradeLogsTableRow from './TradeLogsTableRow';

export interface ITradeLogsTable {
	type: ETradeLogsType;
}

const TradeLogsTable: FC<ITradeLogsTable> = ({ type }) => {
	const { tradeLogs, loading } = useSelector(getTradeLogs);
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState(tradeLogs?.current_page || 1);
	const [perPage, setPerPage] = useState<number | undefined>(10);

	useEffect(() => {
		type === ETradeLogsType.EXTERNAL &&
			dispatch(getExternalLogsRequest({ current_page: currentPage, per_page: perPage }));
		type === ETradeLogsType.INTERNAL &&
			dispatch(getInternalLogsRequest({ current_page: currentPage, per_page: perPage }));
	}, [currentPage, dispatch, perPage, type]);

	useEffect(() => {
		setCurrentPage(1);
	}, [type]);

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || tradeLogs?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = tradeLogs?.last_page ? tradeLogs.last_page : 1;

	const handleDownloadCsvFile = () => {};

	const handleDownloadXlsxFile = () => {
		type === ETradeLogsType.EXTERNAL
			? dispatch(getExternalLogsReport('xlsx'))
			: dispatch(getInternalLogsReport('xlsx'));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div
						className={`table table--type2 table--${
							type === ETradeLogsType.EXTERNAL ? 'external-logs' : 'internal-logs'
						}`}
					>
						<div className="table-header">
							<TradeLogsTableHeader type={type} />
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{tradeLogs?.data.length ? (
										tradeLogs.data.map((log) => (
											<TradeLogsTableRow key={log.id} item={log} type={type} />
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!tradeLogs?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} />
							{!loading && pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
							<div className="button-wrap">
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--csv" onClick={handleDownloadCsvFile} />
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button type="button" className="btn btn--xlsx" onClick={handleDownloadXlsxFile} />
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TradeLogsTable;
