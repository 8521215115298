import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import { hideLoader, loginRequest, loginRequestStep1 } from 'redux/reducers/auth/reducer';
import { useHistory } from 'react-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';
import { navListBeforeLogin } from 'routes/routesList';
import { ILoginSubmitValue } from './types';
import { notificationContainer } from '../../services/utils/notificationContainer';
import InputTotp from '../../ui/Formik/InputTotp';
import { getLoginLoader } from '../../redux/reducers/auth/selectors';

const LoginForm: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const loading = useSelector(getLoginLoader);
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const [isShowPass, setIsShowPass] = useState(false);
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);
	const [currentStep, setCurrentStep] = useState(1);
	// const [formValues, setFormValues] = useState<ILoginSubmitValue>({
	// 	email: '',
	// 	password: '',
	// 	totp: '',
	// 	remember: false,
	// });

	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};
	const onSubmit = (obg: ILoginSubmitValue) => {
		const offset = -(new Date().getTimezoneOffset() / 60);
		if (!reCaptchaKey) {
			notificationContainer('Please complete the captcha field.', 'user_blocked', 'Captcha Error');
		} else {
			dispatch(
				loginRequest({
					data: {
						...obg,
						time_zone: offset,
						// captcha: '00000000',
						captcha: reCaptchaKey,
						remember: obg.remember || undefined,
					},
					history,
				}),
			);
		}
	};
	const initialValues = {
		email: '',
		password: '',
		totp: '',
		remember: false,
	};

	const validationStep1 = yup.object().shape({
		email: yup
			.string()
			.required('Please enter your email.')
			.email('Please enter a valid email address.')
			.max(60, 'Email Address is too long. Maximum 60 characters.'),
		password: yup
			.string()
			.required('Please enter your password.')
			// .matches(
			// 	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
			// 	String(
			// 		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			// 	),
			// )
			.max(25, String('Password is too long. Maximum 25 characters.')),
	});
	const validationStep2 = yup.object().shape({
		totp: yup
			.string()
			// .required('Please enter your 2FA code.')
			.max(6, 'Please enter 6 character 2FA code.'),
	});
	const getValidationSchema = (step: number) => {
		switch (step) {
			case 1:
				return validationStep1;
			case 2:
				return validationStep2;
			default:
				return validationStep1;
		}
	};
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		console.log(`Loading: ${loading}`);
	}, [loading]);
	useEffect(() => {
		dispatch(hideLoader());
	}, [dispatch]);
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={getValidationSchema(currentStep)}
			onSubmit={(values: ILoginSubmitValue, { resetForm, setSubmitting, setFieldTouched }) => {
				// onSubmit(values);
				// setSubmitting(false);
				// resetForm();
				if (currentStep === 1) {
					const offset = -(new Date().getTimezoneOffset() / 60);
					dispatch(
						loginRequestStep1({
							apiParams: {
								data: {
									...values,
									time_zone: offset,
									captcha: '00000000',
								},
								history,
							},
							onFinally: (error: [string]) => {
								if (error && error.includes('invalid_credentials')) {
									notificationContainer('Invalid credentials', 'user_blocked', 'Error');
								} else {
									// setFormValues(values);
									// resetForm();
									setCurrentStep(2);
									// setFieldTouched('totp', false, true);
								}
								setSubmitting(false);
							},
						}),
					);
				} else {
					// onSubmit({
					// 	...formValues,
					// 	totp: values.totp,
					// });
					onSubmit(values);
					setSubmitting(false);
				}
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, setFieldTouched }) => (
				<Form className="form">
					{currentStep === 1 ? (
						<>
							<div className="login-form__title mb-16">
								<p>Login</p>
							</div>
							<div className="login-form-item">
								<div className="input">
									<Field
										required
										name="email"
										type="email"
										placeholder="Email"
										component={Input}
										title="Email"
									/>
								</div>
							</div>
							<div className="login-form-item">
								<div className="input">
									<Field
										required
										name="password"
										type={isShowPass ? 'text' : 'password'}
										ariaLabel="show-password"
										placeholder="Password"
										component={Input}
										title="Password"
										isShowPass={isShowPass}
										setIsShowPass={setIsShowPass}
										link="/reset-password"
										linkTitle="Forgot Password?"
									/>
								</div>
							</div>
							<div className="login-form-item">
								<button
									type="submit"
									aria-label="form-submit"
									className="btn btn-primary btn--full"
									disabled={loading}
								>
									Next
								</button>
							</div>
						</>
					) : (
						<>
							<div className="login-form__title mb-16">
								<div className="d-flex align-items-center gap-16">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										className="btn-back"
										onClick={() => {
											setCurrentStep(1);
											// setFieldTouched('email', true, true);
											// setFieldTouched('password', true, true);
											// setFieldTouched('totp', false, true);
										}}
									/>
									<p>Login confirmation</p>
								</div>
							</div>
							<div className="login-form-item">
								<div className="input">
									<Field
										required
										name="totp"
										type="text"
										placeholder="Code"
										component={InputTotp}
										className="input-item--pr-125"
										title="Authentication Code"
										link={navListBeforeLogin.resetTwoFaPage.path}
										linkTitle="Reset 2FA Code"
									/>
								</div>
							</div>
							<div className="login-recaptcha">
								<ReCAPTCHA
									ref={reCaptchaRef}
									onChange={onReCaptchaChange}
									onExpired={() => setReCaptchaKey(null)}
									sitekey={siteKey || ''}
									theme="dark"
									hl="en"
								/>
							</div>
							<div className="login-form-item">
								<button
									type="submit"
									aria-label="form-submit"
									className="btn btn-primary btn--full"
									disabled={loading}
								>
									Login
								</button>
							</div>
						</>
					)}
				</Form>
			)}
		</Formik>
	);
};

export default LoginForm;
