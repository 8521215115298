import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { ETradeLogsType } from 'redux/reducers/logs/types';
import TradeLogsTable from './TradeLogsTable';

const TradeLogs = () => {
	return (
		<div className="user-management">
			<div className="title-block-wrap">
				<div className="title-block">
					<p className="title">Trade Logs</p>
				</div>
			</div>
			<div className="tabs-buttons-wrapper">
				<div className="tabs-buttons tabs-buttons--pending">
					<NavLink to={ROUTES.logs.external_logs}>External Logs</NavLink>
					<NavLink to={ROUTES.logs.internal_logs}>Internal Logs</NavLink>
				</div>
			</div>

			<Switch>
				<Route path={ROUTES.logs.external_logs}>
					<TradeLogsTable type={ETradeLogsType.EXTERNAL} />
				</Route>

				<Route path={ROUTES.logs.internal_logs}>
					<TradeLogsTable type={ETradeLogsType.INTERNAL} />
				</Route>

				<Redirect to={ROUTES.logs.external_logs} />
			</Switch>
		</div>
	);
};

export default TradeLogs;
