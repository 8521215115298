/* eslint-disable react-hooks/exhaustive-deps */

import React, { FC, useLayoutEffect, useState, memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getUsersData } from 'redux/reducers/users/selectors';
import { IUsersRequest } from 'services/api/users/types';

import UsersSearch from 'components/tables/UsersTable/UsersSearch';
import AdminsTable from 'components/tables/AdminsTable';
import { getAdminsRequest } from 'redux/reducers/admins/reducer';
import { getAdmins, getAdminsLoading } from 'redux/reducers/admins/selectors';
import { ROUTES } from 'routes/constants';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';

const AdminManagementPage: FC = memo(() => {
	const admins = useSelector(getAdmins);
	const loading = useSelector(getAdminsLoading);
	const permission = useSelector(getPermissions)?.[EPermissionNames.ADMIN_MANAGEMENT].editable;
	const history = useHistory();
	const dispatch = useDispatch();
	const [query, setQuery] = useState<IUsersRequest>({
		per_page: 10,
		current_page: 1,
	});

	const previousPerPage = useRef(query.per_page);

	useLayoutEffect(() => {
		// Check if per_page has changed
		if (query.per_page !== previousPerPage.current) {
			// Reset current_page to 1
			query.current_page = 1;
			previousPerPage.current = query.per_page;
			dispatch(getAdminsRequest(query));
		}

		dispatch(getAdminsRequest(query));
	}, [query]);

	const handleCreateAdmin = () => {
		history.push(ROUTES.adminManagement.CREATE_ADMIN);
	};

	return (
		<>
			<div className="title-block-wrap">
				<div className="title-block title-block--wrap">
					<p className="title">Admin Management</p>
					{!!permission && (
						<button
							onClick={handleCreateAdmin}
							type="button"
							className="btn btn-primary btn-primary--sm"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
							>
								<path
									d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
									fill="white"
								/>
							</svg>
							Add New Admin
						</button>
					)}
				</div>
			</div>
			<UsersSearch total={admins?.data?.total} setQuery={setQuery} tab="admin_tab" />

			<div className="table-users">
				<span>All users:</span>
				<span className="table-users__total">{admins?.data?.total}</span>
			</div>

			<AdminsTable
				users={admins?.data?.users || []}
				pages={admins?.data?.last_page || 1}
				query={query}
				loading={loading}
				permission={!!permission}
				itemsPerPage={query.per_page ?? 10}
				setItemsPerPage={(item) => {
					setQuery({ ...query, per_page: Number(item) });
				}}
				setCurrentPage={(page: number) => {
					setQuery({ ...query, current_page: page });
				}}
			/>
		</>
	);
});

export default AdminManagementPage;
