/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeEvent, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminRequiredEmailsRequest, setUserData } from 'redux/reducers/users/reducer';
import { getUserCreateData, getRequiredEmails } from 'redux/reducers/users/selectors';

const EmailsTab = () => {
	const dispatch = useDispatch();
	const requiredEmails = useSelector(getRequiredEmails);
	const userData = useSelector(getUserCreateData);

	// useLayoutEffect(() => {
	// 	console.log(requiredEmails);
	// }, [requiredEmails]);

	useLayoutEffect(() => {
		dispatch(getAdminRequiredEmailsRequest({}));
	}, [dispatch]);

	useLayoutEffect(() => {
		if (
			userData?.email_management &&
			Object.values(userData?.email_management).find((item) => item)
		)
			return;
		const newFee = {
			...userData?.email_management,
			user_registration_pending: requiredEmails?.user_registration_pending,
			user_registration_verified: requiredEmails?.user_registration_verified,
			user_login_notification: requiredEmails?.user_login_notification,
			user_google2fa_connected: requiredEmails?.user_google2fa_connected,
			user_google2fa_disconnected: requiredEmails?.user_google2fa_disconnected,
			user_reset_password: requiredEmails?.user_reset_password,
			user_reset_google2fa: requiredEmails?.user_reset_google2fa,
			user_withdraw_pending: requiredEmails?.user_withdraw_pending,
			user_withdraw_completed: requiredEmails?.user_withdraw_completed,
			user_withdraw_rejected: requiredEmails?.user_withdraw_rejected,
			user_withdraw_cancelled: requiredEmails?.user_withdraw_cancelled,
			user_withdraw_failed: requiredEmails?.user_withdraw_failed,
			user_deposit_pending: requiredEmails?.user_deposit_pending,
			user_deposit_completed: requiredEmails?.user_deposit_completed,
			user_deposit_rejected: requiredEmails?.user_deposit_rejected,
			user_deposit_cancelled: requiredEmails?.user_deposit_cancelled,
			user_deposit_failed: requiredEmails?.user_deposit_failed,
			user_trade_confirmation: requiredEmails?.user_trade_confirmation,
			admin_registration_pending: requiredEmails?.admin_registration_pending,
			admin_registration_verified: requiredEmails?.admin_registration_verified,
			admin_login_notification: requiredEmails?.admin_login_notification,
			admin_google2fa_connected: requiredEmails?.admin_google2fa_connected,
			admin_google2fa_disconnected: requiredEmails?.admin_google2fa_disconnected,
			admin_reset_password: requiredEmails?.admin_reset_password,
			admin_reset_google2fa: requiredEmails?.admin_reset_google2fa,
			admin_withdraw_pending: requiredEmails?.admin_withdraw_pending,
			admin_withdraw_completed: requiredEmails?.admin_withdraw_completed,
			admin_withdraw_rejected: requiredEmails?.admin_withdraw_rejected,
			admin_withdraw_cancelled: requiredEmails?.admin_withdraw_cancelled,
			admin_withdraw_failed: requiredEmails?.admin_withdraw_failed,
			admin_deposit_pending: requiredEmails?.admin_deposit_pending,
			admin_deposit_completed: requiredEmails?.admin_deposit_completed,
			admin_deposit_rejected: requiredEmails?.admin_deposit_rejected,
			admin_deposit_cancelled: requiredEmails?.admin_deposit_cancelled,
			admin_deposit_failed: requiredEmails?.admin_deposit_failed,
			admin_trade_confirmation: requiredEmails?.admin_trade_confirmation,
		};
		dispatch(setUserData({ ...userData, email_management: newFee }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, requiredEmails]);

	const handleTogglerChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;

		dispatch(
			setUserData({
				...userData,
				email_management: { ...userData?.email_management, [name]: checked },
			}),
		);
	};
	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--mob-header table--required-emails">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Name</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Status</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>User</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Admin</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body" onChange={handleTogglerChange}>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Registration</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Pending</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_registration_pending"
													defaultChecked={userData?.email_management?.user_registration_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_registration_pending"
													defaultChecked={userData?.email_management?.admin_registration_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Registration</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Verified</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_registration_verified"
													defaultChecked={userData?.email_management?.user_registration_verified}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_registration_verified"
													defaultChecked={userData?.email_management?.admin_registration_verified}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Login Notification</p>
									</div>
								</div>
								<div className="td  td--mob-hidden">
									<p className="td-hidden-name">Status</p>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_login_notification"
													defaultChecked={userData?.email_management?.user_login_notification}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_login_notification"
													defaultChecked={userData?.email_management?.admin_login_notification}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">2FA</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Connected</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_google2fa_connected"
													defaultChecked={userData?.email_management?.user_google2fa_connected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_google2fa_connected"
													defaultChecked={userData?.email_management?.admin_google2fa_connected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">2FA</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Disconnected</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_google2fa_disconnected"
													defaultChecked={userData?.email_management?.user_google2fa_disconnected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_google2fa_disconnected"
													defaultChecked={userData?.email_management?.admin_google2fa_disconnected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Reset</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Password Reset</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_reset_password"
													defaultChecked={userData?.email_management?.user_reset_password}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_reset_password"
													defaultChecked={userData?.email_management?.admin_reset_password}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Reset</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>2FA Reset</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_reset_google2fa"
													defaultChecked={userData?.email_management?.user_reset_google2fa}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_reset_google2fa"
													defaultChecked={userData?.email_management?.admin_reset_google2fa}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Pending</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_withdraw_pending"
													defaultChecked={userData?.email_management?.user_withdraw_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_withdraw_pending"
													defaultChecked={userData?.email_management?.admin_withdraw_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Completed</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_withdraw_completed"
													defaultChecked={userData?.email_management?.user_withdraw_completed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_withdraw_completed"
													defaultChecked={userData?.email_management?.admin_withdraw_completed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Rejected</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_withdraw_rejected"
													defaultChecked={userData?.email_management?.user_withdraw_rejected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_withdraw_rejected"
													defaultChecked={userData?.email_management?.admin_withdraw_rejected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Cancelled</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_withdraw_cancelled"
													defaultChecked={userData?.email_management?.user_withdraw_cancelled}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_withdraw_cancelled"
													defaultChecked={userData?.email_management?.admin_withdraw_cancelled}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Failed</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_withdraw_failed"
													defaultChecked={userData?.email_management?.user_withdraw_failed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_withdraw_failed"
													defaultChecked={userData?.email_management?.admin_withdraw_failed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Pending</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_deposit_pending"
													defaultChecked={userData?.email_management?.user_deposit_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_deposit_pending"
													defaultChecked={userData?.email_management?.admin_deposit_pending}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Completed</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_deposit_completed"
													defaultChecked={userData?.email_management?.user_deposit_completed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_deposit_completed"
													defaultChecked={userData?.email_management?.admin_deposit_completed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Rejected</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_deposit_rejected"
													defaultChecked={userData?.email_management?.user_deposit_rejected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_deposit_rejected"
													defaultChecked={userData?.email_management?.admin_deposit_rejected}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Cancelled</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_deposit_cancelled"
													defaultChecked={userData?.email_management?.user_deposit_cancelled}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_deposit_cancelled"
													defaultChecked={userData?.email_management?.admin_deposit_cancelled}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
									</div>
								</div>
								<div className="td">
									<p className="td-hidden-name">Status</p>
									<div className="td__wrap td__wrap--column td__wrap--gap-4">
										<p>Failed</p>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_deposit_failed"
													defaultChecked={userData?.email_management?.user_deposit_failed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_deposit_failed"
													defaultChecked={userData?.email_management?.admin_deposit_failed}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>

							<div className="tr">
								<div className="td">
									<p className="td-hidden-name">Name</p>
									<div className="td__wrap td__wrap--column">
										<p className="font-weight-medium">Trade Confirmation</p>
									</div>
								</div>
								<div className="td td--mob-hidden">
									<p className="td-hidden-name">Status</p>
								</div>

								<div className="td">
									<p className="td-hidden-name">User Name</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="user_trade_confirmation"
													defaultChecked={userData?.email_management?.user_trade_confirmation}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>

								<div className="td">
									<p className="td-hidden-name">Admin</p>
									<div className="flex flex--column flex--gap-4">
										<div className="switch">
											<label className="switch__label">
												<input
													type="checkbox"
													className="hidden"
													name="admin_trade_confirmation"
													defaultChecked={userData?.email_management?.admin_trade_confirmation}
												/>
												<span className="switch__toggler" />
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EmailsTab;
