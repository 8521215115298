import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { FC } from 'react';
import { getCurrencyData } from 'redux/reducers/currency/selectors';
import { useSelector } from 'react-redux';
import { ITradeHistoryRowProps } from '../../CryptoHistory/types';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

const TradeHistoryRow: FC<ITradeHistoryRowProps> = ({ item }) => {
	const [date, time] = useFormatDateTime(item.created_at);

	const currency = useSelector(getCurrencyData);

	const sellCurrency = currency.filter((currencyItem) => currencyItem.id === item.from_asset_id)[0];
	const buyCurrency = currency.filter((currencyItem) => currencyItem.id === item.to_asset_id)[0];
	function splitText(text: string) {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	}
	const [oneText, twoText] = splitText(item.pair || 'btc_eur');
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>

				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Sell</p>
				<p className="td-name--flex">{!!sellCurrency && sellCurrency?.code.toUpperCase()}</p>
				<span className="td-more-info">{!!sellCurrency && sellCurrency?.name}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Buy</p>
				<p className="td-name--flex">{!!buyCurrency && buyCurrency?.code.toUpperCase()}</p>
				<span className="td-more-info">{!!buyCurrency && buyCurrency.name}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount Sold</p>
				<p>{!!sellCurrency && toFixedNumber(item.quantity, sellCurrency?.code, true)}</p>
				<span className="td-more-info">{!!sellCurrency && sellCurrency?.code.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount Received</p>
				<p>{!!buyCurrency && toFixedNumber(item.amount, buyCurrency.code, true)}</p>
				<span className="td-more-info">{!!buyCurrency && buyCurrency.code.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Exchange Rate</p>
				<p>{toFixedNumber(item.price_b2c2, 'crypto', true)}</p>
				<span className="td-more-info">{twoText.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<p>{!!sellCurrency && toFixedNumber(item.fee, sellCurrency?.code, true)}</p>
				<span className="td-more-info">{!!sellCurrency && sellCurrency?.code.toUpperCase()}</span>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Tx ID</p>
				<p>{item.id}</p>
			</div>
		</div>
	);
};

export default TradeHistoryRow;
