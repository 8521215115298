import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILogsRequestPayload, ILogsStore, ITradeLogsWithPagination } from './types';

export const initialState: ILogsStore = {
	tradeLogs: null,
	loading: false,
};

const logs = createSlice({
	name: '@@logs',
	initialState,
	reducers: {
		getExternalLogsRequest: (state, action: PayloadAction<ILogsRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		getExternalLogsSuccess: (state, action: PayloadAction<ITradeLogsWithPagination>) => {
			const newState = state;
			newState.tradeLogs = action.payload;
			newState.loading = false;
		},
		getExternalLogsReport: (state, action: PayloadAction<string>) => {},
		getInternalLogsRequest: (state, action: PayloadAction<ILogsRequestPayload>) => {
			const newState = state;
			newState.loading = true;
		},
		getInternalLogsSuccess: (state, action: PayloadAction<ITradeLogsWithPagination>) => {
			const newState = state;
			newState.tradeLogs = action.payload;
			newState.loading = false;
		},
		getInternalLogsReport: (state, action: PayloadAction<string>) => {},
	},
});

export default logs.reducer;

export const {
	getExternalLogsRequest,
	getExternalLogsSuccess,
	getExternalLogsReport,
	getInternalLogsReport,
	getInternalLogsRequest,
	getInternalLogsSuccess,
} = logs.actions;
