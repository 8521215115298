import React from 'react';
import { Helmet } from 'react-helmet';
import logo from 'assets/dist/img/logo2.svg';
import maintenanceIcon from 'assets/dist/img/maintenance-icon.svg';
import Dashboard from '../../layouts/Dashboard';

const MaintenancePage = () => {
	return (
		<>
			<Helmet>
				<body className="maintenance-page" />
			</Helmet>
			<>
				<section className="maintenance">
					<div className="maintenance__content">
						<div className="maintenance__logo">
							<a href="!#">
								<img alt="Logo" src={logo} />
							</a>
						</div>
						<div className="maintenance__title">Update in progress</div>
						<div className="maintenance__icon">
							<img src={maintenanceIcon} alt="maintenance icon" />
						</div>
						<div className="maintenance__text">
							Kaiserex platform is temporarily offline for routine updates. We’ll be back online
							shortly.
						</div>
					</div>
				</section>
				<div className="maintenance__footer">2022 - 2023 Kaiserex.com All rights reserved</div>
			</>
		</>
	);
};
export default MaintenancePage;
