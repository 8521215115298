import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { roundingNumber } from '../../../../../services/utils/roundingNumber';
import { popUpOpen, setPopUpData } from '../../../../../redux/reducers/popUp/reducer';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';
/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
	item: any;
	permission: boolean;
}

const Item: FC<Props> = ({ item, permission }) => {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { code, total_debit } = item;
	const dispatch = useDispatch();

	const handleProfitWalletSend = () => {
		const data = {
			title: 'Send to Pool Wallet',
			currency: code.toUpperCase(),
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			depositAmount: `${roundingNumber(total_debit, code)} ${code.toUpperCase()}`,
			payload: {
				type: 'Deposit wallets crypto',
				asset_id: item.asset_id,
				chain_id: item.chain_id,
			},
			total_balance: total_debit,
		};
		dispatch(popUpOpen('walletBalancesTwoFaCodePopUp'));
		dispatch(setPopUpData(data));
	};
	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<div className="td__wrap">
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<img src={`/img/currencies/${code.toLowerCase()}.svg`} alt={code} />
						<p>{code.toUpperCase()}</p>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Deposit Wallets</p>
					<div className="td__wrap">
						<p>{toFixedNumber(total_debit, code, true)}</p>
					</div>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name" />
					{permission && (
						<button
							onClick={handleProfitWalletSend}
							className="button button--size2 button--width-auto"
							type="button"
						>
							Send to Pool Wallet
						</button>
					)}
				</div>
			</div>
		</>
	);
};

export default Item;
