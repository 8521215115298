import { FC } from 'react';
import { ETradeLogsType } from 'redux/reducers/logs/types';
import { ITradeLogsTable } from '.';

const TradeLogsTableHeader: FC<ITradeLogsTable> = ({ type }) => {
	return (
		<div className="tr">
			{type === ETradeLogsType.INTERNAL && (
				<div className="td">
					<div className="td-name">
						<p>Timestamp</p>
					</div>
				</div>
			)}
			{type === ETradeLogsType.EXTERNAL && (
				<div className="td">
					<div className="td-name">
						<p>ID</p>
					</div>
				</div>
			)}
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			{type === ETradeLogsType.EXTERNAL && (
				<div className="td">
					<div className="td-name">
						<p>Tx ID</p>
					</div>
				</div>
			)}
			<div className="td">
				<div className="td-name">
					<p>Sell / Buy</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Sell amount / Buy amount</p>
				</div>
			</div>
			{type === ETradeLogsType.INTERNAL && (
				<div className="td td--right">
					<div className="td-name">
						<p>Side</p>
					</div>
				</div>
			)}

			<div className="td td--right">
				<div className="td-name">
					<p>Quantity</p>
				</div>
			</div>
			<div className="td td--right">
				<div className="td-name">
					<p>Crypto quantity</p>
				</div>
			</div>
			{type === ETradeLogsType.INTERNAL && (
				<>
					<div className="td td--right">
						<div className="td-name">
							<p>User Balance</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Trade Fee</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Spread Fee</p>
						</div>
					</div>
				</>
			)}
			<div className="td td--right">
				<div className="td-name">
					<p>Rate / Rate with spread</p>
				</div>
			</div>
			{type === ETradeLogsType.EXTERNAL && (
				<>
					<div className="td">
						<div className="td-name">
							<p>Side</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Request</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Response</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Created at</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name">
							<p>Updated at</p>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default TradeLogsTableHeader;
