import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { IUserDataSpreadFees, IUserFeesSpreadUpdteBody } from 'redux/reducers/users/types';
import { PencilSmallIcon, CheckIcon, CloseIcon } from 'assets/inline-svg';
import { numberInputFix } from 'services/utils/numberInputFix';
import { roundingNumber } from 'services/utils/roundingNumber';
import { toMaxDecimals } from 'services/utils/numbers';
import { handleFeeFixedChange } from 'services/utils/decimalLimits';
import { toFixedNumber } from '../../../../../services/utils/toFixedNumber';

export interface IFeeManagementTradeItem {
	el: IUserDataSpreadFees;
	permission: boolean;
	updateHandler: (obj: IUserFeesSpreadUpdteBody) => void;
}

const FeeManagementSpreadItem: FC<IFeeManagementTradeItem> = ({
	el,
	updateHandler,
	permission,
}) => {
	const { pair, spread, id } = el;
	const [changeableMode, setChangeableMode] = useState<boolean>(false);

	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		spread: spread || null || '',
	};

	const splitText = (text: string) => {
		const [oneText, twoText] = text.split('_');
		return [oneText, twoText];
	};

	const [oneText, twoText] = splitText(pair?.code.toUpperCase());

	return (
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						updateHandler({
							spread_id: id,
							spread: Number(values?.spread),
						});
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr tr--editable" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Pair</p>
								{/* {pair?.code.toUpperCase().replace('_', '/')} */}
								<div className="td__coin-pair">
									<div className="td__coin-pair-item">
										{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
										<img src={`/img/currencies/${oneText.toLowerCase()}.svg`} alt={oneText} />
										<p className="td-currency-name">{el.pair.base_asset.code.toUpperCase()} </p>
										<span className="td-more-info d-inline">{el.pair.base_asset.name}</span>
									</div>
									<div className="td__coin-pair-divider">×</div>
									<div className="td__coin-pair-item">
										{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
										<img src={`/img/currencies/${twoText.toLowerCase()}.svg`} alt={twoText} />
										<p className="td-currency-name">{el.pair.quote_asset.code.toUpperCase()} </p>
										<span className="td-more-info d-inline">{el.pair.quote_asset.name}</span>
									</div>
								</div>
							</div>
							<div className="td">
								<p className="td-hidden-name">Spread %</p>
								<div className="input">
									<div className="input-wrapper">
										<input
											className="table__input"
											type="number"
											onKeyDown={(event) => numberInputFix(event)}
											name="spread"
											onChange={(event) =>
												handleFeeFixedChange(event, props.setFieldValue, 'spread', 'percent')
											}
											onWheel={(event) => event.currentTarget.blur()}
											onBlur={props.handleBlur}
											value={props?.values?.spread}
											placeholder="0"
											max={20}
										/>
									</div>
								</div>
							</div>

							<div className="td td--right">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOffChangeableMode}
										className="btn--icon btn--cancel"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button type="submit" className="btn--icon btn--check" />
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Pair</p>
						{/* {pair?.code.toUpperCase().replace('_', '/')} */}
						<div className="td__coin-pair">
							<div className="td__coin-pair-item">
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<img src={`/img/currencies/${oneText.toLowerCase()}.svg`} alt={oneText} />
								<p className="td-currency-name">{el.pair.base_asset.code.toUpperCase()} </p>
								<span className="td-more-info d-inline">{el.pair.base_asset.name}</span>
							</div>
							<div className="td__coin-pair-divider">×</div>
							<div className="td__coin-pair-item">
								{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
								<img src={`/img/currencies/${twoText.toLowerCase()}.svg`} alt={twoText} />
								<p className="td-currency-name">{el.pair.quote_asset.code.toUpperCase()} </p>
								<span className="td-more-info d-inline">{el.pair.quote_asset.name}</span>
							</div>
						</div>
					</div>
					<div className="td">
						<p className="td-hidden-name">Spread %</p>
						<div className="td__wrap">
							<p>{toFixedNumber(spread, '')}</p>
						</div>
					</div>
					<div className="td  td--right">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							{permission && (
								<>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										type="button"
										onClick={switchOnChangeableMode}
										className="btn--icon btn--edit"
									/>
								</>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default FeeManagementSpreadItem;
