import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import axios from 'axios';
import { responseErrors } from 'services/http/responseErrors';
import {
	getExternalLogsReport,
	getExternalLogsRequest,
	getExternalLogsSuccess,
	getInternalLogsReport,
	getInternalLogsRequest,
	getInternalLogsSuccess,
} from './reducer';
import { ILogsRequestPayload, ITradeLogsWithPagination } from './types';

function* getExternalLogsWorker({ payload }: PayloadAction<ILogsRequestPayload>) {
	try {
		const data: ITradeLogsWithPagination = yield call(api.logs.getExternalTradeLogs, payload);

		yield put(getExternalLogsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getExternalLogsReportWorker({ payload }: PayloadAction<string>) {
	try {
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);

		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/trade_logs/report/${payload}/${token.secret}/1`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getInternalLogsWorker({ payload }: PayloadAction<ILogsRequestPayload>) {
	try {
		const data: ITradeLogsWithPagination = yield call(api.logs.getInternalTradeLogs, payload);

		yield put(getExternalLogsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getInternalLogsReportWorker({ payload }: PayloadAction<string>) {
	try {
		const token: { secret: string } = yield call(api.auth.postGenerateSecretTokenForFileDownload);

		window.location.replace(
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			`${process.env.REACT_APP_WEB_API_URL}/admin/internal_trade_logs/report/${payload}/${token.secret}/1`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* logsSaga() {
	yield takeLatest(getExternalLogsRequest, getExternalLogsWorker);
	yield takeLatest(getExternalLogsReport, getExternalLogsReportWorker);
	yield takeLatest(getInternalLogsRequest, getInternalLogsWorker);
	yield takeLatest(getInternalLogsReport, getInternalLogsReportWorker);
}
