import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { addFilter } from 'redux/reducers/transactionsFilters/reducer';
import FilterDateSelect from 'ui/Formik/Select/FilterDateSelect';
import SortedButton, { TSortedDirection } from 'ui/SortedButton';
import { ETradeSortedFields } from 'services/api/transactions/types';
import { ITransactionsTab } from '../../types';

const TradesTableHeader: FC<ITransactionsTab> = ({ setSorted }) => {
	const dispatch = useDispatch();
	const [quantitySorted, setQuantitySorted] = useState<TSortedDirection>('');
	const [amountSorted, setAmountSorted] = useState<TSortedDirection>('');
	const [rateSorted, setRateSorted] = useState<TSortedDirection>('');
	const [feeSorted, setFeeSorted] = useState<TSortedDirection>('');

	const onSortedQuantity = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ETradeSortedFields.QUANTITY, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setQuantitySorted(direction);
		setAmountSorted('');
		setRateSorted('');
		setFeeSorted('');
	};
	const onSortedAmount = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ETradeSortedFields.AMOUNT, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setAmountSorted(direction);
		setQuantitySorted('');
		setRateSorted('');
		setFeeSorted('');
	};
	const onSortedRate = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ETradeSortedFields.RATE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setRateSorted(direction);
		setAmountSorted('');
		setQuantitySorted('');
		setFeeSorted('');
	};
	const onSortedFee = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ETradeSortedFields.FEE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setFeeSorted(direction);
		setQuantitySorted('');
		setAmountSorted('');
		setRateSorted('');
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_TRADES,
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					{/* <FilterDateSelect title="Timestamp" onChange={onChangeDate} /> */}
					<p>Timestamp</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Sell</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Buy</p>
				</div>
			</div>
			<div className="td">
				<SortedButton
					direction={quantitySorted}
					title="Amount Sold"
					handleSort={onSortedQuantity}
				/>
			</div>
			<div className="td">
				<SortedButton
					direction={amountSorted}
					title="Amount Received"
					handleSort={onSortedAmount}
				/>
			</div>
			<div className="td">
				<SortedButton direction={rateSorted} title="Exchange Rate" handleSort={onSortedRate} />
			</div>
			<div className="td">
				<SortedButton direction={feeSorted} title="Fee" handleSort={onSortedFee} />
			</div>
			<div className="td">
				<div className="td-name">
					<p>Spread Fee</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Tx ID</p>
				</div>
			</div>
		</div>
	);
};

export default TradesTableHeader;
