import { http } from 'services/http';
import { endpoint } from 'services/endpoint';

import { ILogsApi } from './types';

export const logs: ILogsApi = {
	getExternalTradeLogs: (payload) =>
		http.get(endpoint.logs.GET_EXTERNAL_TRADE_LOGS, { params: payload }).then(({ data }) => data),
	getInternalTradeLogs: (payload) =>
		http.get(endpoint.logs.GET_INTERNAL_TRADE_LOGS, { params: payload }).then(({ data }) => data),
};
