/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTokenRefreshTime } from 'services/utils/tokenRefresh';
import {
	EPermissionNames,
	IAuthStore,
	ICreateNewPasswordFormData,
	IForgotPasswordPayload,
	ILoginFirstRequest,
	ILoginPayload,
	ILoginResponse,
	ILoginStep1Payload,
	ILogoutPayload,
	IPermissions,
	IResetTwoFaRequestPayload,
	ITokenRefreshResponse,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	userData: null,
	accessToken: null,
	socketToken: null,
	refreshToken: null,
	isAuthenticated: false,
	registerLoader: false,
	logoutLoader: false,
	loginLoader: false,
	forgotPasswordLoader: false,
	forgotPasswordPopup: false,
	createNewPasswordLoader: false,
	ipConfirmLoader: false,
	emailConfirm: false,
	emailConfirmLoader: false,
	emailResetConfirmTokenLoader: false,
	emailResetConfirmTokenMessage: false,
	generateSmsLoader: false,
	tokenRefreshTime: null,
	tokenUpdating: false,
	secretTokenForFileDownload: null,
	permissions: null,
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequestStep1: (state, action: PayloadAction<ILoginStep1Payload>) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = true;
		},
		hideLoader: (state) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = false;
		},
		loginRequest: (state, action: PayloadAction<ILoginPayload>) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = true;
		},
		loginSuccess: (state, action: PayloadAction<ILoginResponse>) => {
			const { payload } = action;
			const loginState = state;
			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.socketToken = payload.socket_token;
			loginState.userData = payload.user_data;
			loginState.tokenRefreshTime = getTokenRefreshTime();
			loginState.permissions = payload.user_data.permissions;
		},

		forgotPasswordRequest: (state, action: PayloadAction<IForgotPasswordPayload>) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = true;
		},
		forgotPasswordSuccess: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = false;
			forgotPasswordState.forgotPasswordPopup = true;
		},
		forgotPasswordPopupClose: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordPopup = false;
		},

		logoutRequest: (state, action: PayloadAction<ILogoutPayload>) => {
			const loginRequestState = state;
			loginRequestState.logoutLoader = true;
		},
		refreshTokenRequest: (state) => {
			const loginRequestState = state;
			loginRequestState.tokenUpdating = true;
			loginRequestState.logoutLoader = true;
		},
		refreshTokenSuccess: (state, action: PayloadAction<ITokenRefreshResponse>) => {
			const { payload } = action;
			const loginState = state;
			loginState.tokenUpdating = false;
			loginState.accessToken = payload.token;
			loginState.tokenRefreshTime = getTokenRefreshTime();
		},
		generateSecretTokenForFileDownloadRequest: () => {},
		generateSecretTokenForFileDownloadSuccess: (state, { payload }: PayloadAction<string>) => {
			state.secretTokenForFileDownload = payload;
		},
		getCurrentAdminPermissionsRequest: (state, action) => {},
		getCurrentAdminPermissionsSuccess: (state, action: PayloadAction<any>) => {
			const { payload } = action;
			const newState = state;
			newState.permissions = payload.data.users[0].permissions;
		},
		resetTwoFaRequest: (state, action: PayloadAction<IResetTwoFaRequestPayload>) => {},
		loginFirst: (state, action: PayloadAction<ILoginFirstRequest>) => {},
		createNewPasswordRequest: (state, action: PayloadAction<ICreateNewPasswordFormData>) => {},
		authInitState: () => initialState,
		// DELETE after completing with real permission
		addLogsPermission: (state, action: PayloadAction<IPermissions>) => {
			const newState = state;
			if (newState.permissions) {
				state.permissions = [...newState.permissions, action.payload];
			}
		},
	},
});

export default auth.reducer;
export const {
	loginRequest,
	loginRequestStep1,
	loginSuccess,
	logoutRequest,
	authInitState,
	refreshTokenRequest,
	refreshTokenSuccess,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	forgotPasswordPopupClose,
	generateSecretTokenForFileDownloadRequest,
	generateSecretTokenForFileDownloadSuccess,
	getCurrentAdminPermissionsRequest,
	getCurrentAdminPermissionsSuccess,
	resetTwoFaRequest,
	loginFirst,
	createNewPasswordRequest,
	hideLoader,
	addLogsPermission,
} = auth.actions;
