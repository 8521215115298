import { spawn } from 'redux-saga/effects';

import { authSaga } from './reducers/auth/saga';
import { confirmationSaga } from './reducers/confirmation/saga';
import { settingsSaga } from './reducers/settings/saga';
import { transactionsSaga } from './reducers/transactions/saga';
import { usersSaga } from './reducers/users/saga';
import { twoFaSaga } from './reducers/twoFa/saga';
import { bankAccountsSaga } from './reducers/bankAccounts/saga';
import { walletAddressesSaga } from './reducers/walletAddresses/saga';
import { referralSaga } from './reducers/referrals/saga';
import { feeSaga } from './reducers/fee/saga';
import { currencySaga } from './reducers/currency/saga';
import { walletBalanceSaga } from './reducers/walletBalance/saga';
import { dashboardSaga } from './reducers/dashboard/saga';
import { adminsSaga } from './reducers/admins/saga';
import { downloadSaga } from './reducers/download/saga';
import { detailedViewSaga } from './reducers/detailedView/saga';
import { messagesSaga } from './reducers/messages/saga';
import { logsSaga } from './reducers/logs/saga';

// ==========================================:

export default function* rootSagas() {
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(twoFaSaga);
	yield spawn(transactionsSaga);
	yield spawn(usersSaga);
	yield spawn(confirmationSaga);
	yield spawn(bankAccountsSaga);
	yield spawn(walletAddressesSaga);
	yield spawn(feeSaga);
	yield spawn(referralSaga);
	yield spawn(currencySaga);
	yield spawn(walletBalanceSaga);
	yield spawn(dashboardSaga);
	yield spawn(adminsSaga);
	yield spawn(downloadSaga);
	yield spawn(detailedViewSaga);
	yield spawn(messagesSaga);
	yield spawn(logsSaga);
}
