/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { FC, useLayoutEffect, useState } from 'react';
import Popup from 'reactjs-popup';
// import { useHistory } from 'react-router';
import { Formik, Form, Field } from 'formik';
import Input from 'ui/Formik/Input';
import PhoneInputField from 'ui/PhoneInputField';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { supportRequest } from 'redux/reducers/download/reducer';
import { getUserData } from 'redux/reducers/auth/selectors';
import { CountryCode, getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js';
import { IPopUp } from '../types';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';
import { getSupportLoading } from '../../../redux/reducers/download/selectors';
import { ISupportRequestPayload } from '../../../redux/reducers/download/types';
import InputFloatingLabel from '../../../ui/Formik/InputFloatingLabel';
// import { IGetTransactionHistoryRequestPayload } from '../../../redux/reducers/transactions/types';

const SupportPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	// const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const supportLoading = useSelector(getSupportLoading);
	const [counterStateCode, setStateCounterCode] = useState('US');
	const [phonePrefix, setPhonePrefix] = useState<string>('1');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const [fullName, setFullName] = useState<string>('');
	const onChange = (value: string) => {
		setPhonePrefix(value);
	};

	const initialValues = {
		email: userData?.email || '',
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		fullName: `${userData?.first_name} ${userData?.last_name}` || '',
		phone: phoneNumber,
		message: '',
	};

	useLayoutEffect(() => {
		if (userData?.data) {
			// const fullPhone = String(userData?.data?.phone).replace('+', '');
			// const phoneNumberValue = parsePhoneNumber(`+${fullPhone}`);
			// const country = phoneNumberValue?.country;
			// const countryCallingCode = getCountryCallingCode(country as CountryCode);

			// setPhonePrefix(countryCallingCode);
			// setPhoneNumber(fullPhone.replace(countryCallingCode, ''));
			if (userData?.data?.phone) setPhoneNumber(`+${String(userData?.data?.phone)}`);
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			setFullName(`${userData?.data?.first_name} ${userData?.data?.last_name}`);
		}
	}, [userData?.data]);

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(notificationsInfoFields.email.required)
			.email(notificationsInfoFields.email.invalid)
			.max(60, notificationsInfoFields.email.maxLength),
		fullName: yup
			.string()
			.min(1, notificationsInfoFields.fullName.min)
			.max(50, notificationsInfoFields.fullName.max)
			.required(notificationsInfoFields.fullName.required),
		phone: yup
			.string()
			.required(notificationsInfoFields.phone.required)
			.max(20, notificationsInfoFields.phone.max),
		// .matches(/^\d{9,20}$/, notificationsInfoFields.phone.matches),
		message: yup
			.string()
			.required(notificationsInfoFields.message.required)
			.min(2, notificationsInfoFields.message.min)
			.max(255, notificationsInfoFields.message.max),
	});
	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					// history.push('/');
				}}
			>
				<div className="modal">
					<div className="popup popup--support">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="popup__close-btn" onClick={closeModal} />
						<div className="popup-header">
							<p className="popup-header__title">Support</p>
						</div>
						<Formik
							validationSchema={validationSchema}
							initialValues={initialValues}
							onSubmit={(obj, { resetForm, setSubmitting }) => {
								// const newObject = {
								// 	...obj,
								// 	phone: phonePrefix + obj.phone,
								// };
								const params: ISupportRequestPayload = {
									apiParams: {
										name: obj.fullName,
										email: obj.email,
										phone: obj.phone,
										message: obj.message,
									},
									onFinally: (hasError: boolean) => {
										if (!hasError) {
											closeModal();
										}
										setSubmitting(false);
										resetForm();
									},
								};
								// console.log(params.apiParams);
								dispatch(supportRequest(params));
							}}
							enableReinitialize
							validateOnBlur
						>
							{({ isSubmitting, isValid, dirty, setFieldValue }) => (
								<Form className="form">
									<div className="popup-body">
										{/* <div className="popup-text">
											<p>
												Need help? Please submit the form below and our Support Team will be in
												touch.
											</p>
										</div> */}
										<Field
											type="email"
											placeholder="Email Address"
											name="email"
											required
											component={InputFloatingLabel}
											id="support_email"
											classNameWrap="input--floating mb-32"
										/>
										<Field
											type="text"
											placeholder="Full Name"
											name="fullName"
											required
											component={InputFloatingLabel}
											id="support_fullName"
											classNameWrap="input--floating mb-32"
										/>
										<Field
											type="text"
											placeholder="Phone Number"
											name="phone"
											required
											component={InputFloatingLabel}
											id="support_phone"
											classNameWrap="input--floating mb-32"
										/>
										{/* <p className="input__name">Phone Number</p>
										<PhoneInputField
											counterStateCode={counterStateCode}
											onChange={onChange}
											value={phonePrefix}
											enableSearch
											dropdownClass="phone-dropdown"
											searchClass="searchfield"
										>
											<Field
												type="tel"
												placeholder="Phone Number"
												name="phone"
												required
												component={Input}
												onKeyUp={(e: any) => {
													e.preventDefault();
													const { value } = e.target;
													setFieldValue('phone', value.replace(/\D/g, ''));
												}}
												setValue={setFieldValue}
											/>
										</PhoneInputField> */}
										<Field
											type="textarea"
											placeholder="Message"
											name="message"
											required
											component={InputFloatingLabel}
											id="support_message"
											classNameWrap="input--floating mb-40"
										/>
									</div>
									<div className="popup-footer">
										<div className="popup-submit mt-0 mb-40">
											<button
												type="submit"
												className="btn btn-primary btn--small-type2 btn--text-semibold"
												disabled={supportLoading}
											>
												Submit
											</button>
										</div>
									</div>
									<div className="popup-text popup-text--gray mt-0 mb-16">
										<p>You can also reach us on Telegram</p>
									</div>
									<div className="popup-soc">
										<a href="https://t.me/Kaiserex_crypto">
											<svg
												width="40"
												height="40"
												viewBox="0 0 40 40"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<rect width="40" height="40" rx="20" fill="#2E2DED" />
												<path
													d="M28.6646 11.7171L10.9346 18.5541C9.72461 19.0401 9.73161 19.7151 10.7126 20.0161L15.2646 21.4361L25.7966 14.7911C26.2946 14.4881 26.7496 14.6511 26.3756 14.9831L17.8426 22.6841H17.8406L17.8426 22.6851L17.5286 27.3771C17.9886 27.3771 18.1916 27.1661 18.4496 26.9171L20.6606 24.7671L25.2596 28.1641C26.1076 28.6311 26.7166 28.3911 26.9276 27.3791L29.9466 13.1511C30.2556 11.9121 29.4736 11.3511 28.6646 11.7171Z"
													fill="white"
												/>
											</svg>
										</a>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default SupportPopUp;
