import React, { ChangeEvent, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import { getUserRequiredEmailsRequest, setToggler } from 'redux/reducers/users/reducer';
import {
	getOneUser,
	getRequiredEmails,
	getRequiredEmailsLoading,
	getUserAdmin,
} from 'redux/reducers/users/selectors';
import { ERequiredEmails } from 'redux/reducers/users/types';
import Item from '../../WalletBalance/PlatformBalance/Fiat/Item/Item';
import TableBodyNoData from '../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../layouts-elements/Loading/Loading';

const EmailsTab = () => {
	const dispatch = useDispatch();
	const user = useSelector(getOneUser);
	const requiredEmails = useSelector(getRequiredEmails);
	const loading = useSelector(getRequiredEmailsLoading);
	const permissions = useSelector(getPermissions);
	// const userAdmin = useSelector(getUserAdmin);

	useLayoutEffect(() => {
		if (user?.id) {
			// dispatch(getUserRequiredEmailsRequest({ id: user?.id, type: userAdmin }));
			dispatch(getUserRequiredEmailsRequest({ id: user?.id }));
		}
	}, [dispatch, user?.id]);

	const handleChangeRequiredEmails = (e: ChangeEvent<HTMLInputElement>) => {
		const toggler = e.target.name as ERequiredEmails;

		dispatch(setToggler({ toggler, id: user?.id || 0 }));
	};

	return (
		<>
			<div className="table-block mt-30">
				<div className="table-wrapper">
					<div className="table table--mob-header table--required-emails">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Name</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Status</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>User</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Admin</p>
									</div>
								</div>
							</div>
						</div>
						{!loading && (
							<>
								<div className="table-body">
									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Registration</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Pending</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															onChange={handleChangeRequiredEmails}
															className="hidden"
															name="user_registration_pending"
															checked={Boolean(requiredEmails?.user_registration_pending)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															onChange={handleChangeRequiredEmails}
															className="hidden"
															name="admin_registration_pending"
															checked={Boolean(requiredEmails?.admin_registration_pending)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Registration</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Verified</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_registration_verified"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_registration_verified)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_registration_verified"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_registration_verified)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Login Notification</p>
											</div>
										</div>
										<div className="td  td--mob-hidden">
											<p className="td-hidden-name">Status</p>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_login_notification"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_login_notification)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_login_notification"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_login_notification)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">2FA</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Connected</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_google2fa_connected"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_google2fa_connected)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_google2fa_connected"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_google2fa_connected)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">2FA</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Disconnected</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_google2fa_disconnected"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_google2fa_disconnected)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_google2fa_disconnected"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_google2fa_disconnected)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Reset</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Password Reset</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															name="user_reset_password"
															onChange={handleChangeRequiredEmails}
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_reset_password)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															name="admin_reset_password"
															onChange={handleChangeRequiredEmails}
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_reset_password)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Reset</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>2FA Reset</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_reset_google2fa"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_reset_google2fa)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_reset_google2fa"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_reset_google2fa)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Pending</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_withdraw_pending"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_withdraw_pending)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_withdraw_pending"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_withdraw_pending)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Completed</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_withdraw_completed"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_withdraw_completed)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_withdraw_completed"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_withdraw_completed)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Rejected</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_withdraw_rejected"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_withdraw_rejected)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_withdraw_rejected"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_withdraw_rejected)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>
									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Cancelled</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_withdraw_cancelled"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_withdraw_cancelled)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_withdraw_cancelled"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_withdraw_cancelled)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Failed</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_withdraw_failed"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_withdraw_failed)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_withdraw_failed"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_withdraw_failed)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Pending</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															name="user_deposit_pending"
															onChange={handleChangeRequiredEmails}
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_deposit_pending)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															name="admin_deposit_pending"
															onChange={handleChangeRequiredEmails}
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_deposit_pending)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Completed</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="user_deposit_completed"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_deposit_completed)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															onChange={handleChangeRequiredEmails}
															name="admin_deposit_completed"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_deposit_completed)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Rejected</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															onChange={handleChangeRequiredEmails}
															className="hidden"
															name="user_deposit_rejected"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_deposit_rejected)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															onChange={handleChangeRequiredEmails}
															className="hidden"
															name="admin_deposit_rejected"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_deposit_rejected)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Cancelled</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															name="user_deposit_cancelled"
															onChange={handleChangeRequiredEmails}
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_deposit_cancelled)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															name="admin_deposit_cancelled"
															onChange={handleChangeRequiredEmails}
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_deposit_cancelled)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
											</div>
										</div>
										<div className="td">
											<p className="td-hidden-name">Status</p>
											<div className="td__wrap td__wrap--column td__wrap--gap-4">
												<p>Failed</p>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															name="user_deposit_failed"
															onChange={handleChangeRequiredEmails}
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_deposit_failed)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															className="hidden"
															name="admin_deposit_failed"
															onChange={handleChangeRequiredEmails}
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_deposit_failed)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>

									<div className="tr">
										<div className="td">
											<p className="td-hidden-name">Name</p>
											<div className="td__wrap td__wrap--column">
												<p className="font-weight-medium">Trade Confirmation</p>
											</div>
										</div>
										<div className="td td--mob-hidden">
											<p className="td-hidden-name">Status</p>
										</div>

										<div className="td">
											<p className="td-hidden-name">User Name</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															onChange={handleChangeRequiredEmails}
															className="hidden"
															name="user_trade_confirmation"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.user_trade_confirmation)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>

										<div className="td">
											<p className="td-hidden-name">Admin</p>
											<div className="flex flex--column flex--gap-4">
												<div className="switch">
													<label className="switch__label">
														<input
															type="checkbox"
															onChange={handleChangeRequiredEmails}
															className="hidden"
															name="admin_trade_confirmation"
															disabled={!permissions?.[EPermissionNames.USER_MANAGEMENT].editable}
															checked={Boolean(requiredEmails?.admin_trade_confirmation)}
														/>
														<span className="switch__toggler" />
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
						{loading ? <Loading /> : null}
					</div>
				</div>
			</div>
		</>
	);
};

export default EmailsTab;
